import 'bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition.js';
import 'swiper/dist/js/swiper';

$(document).ready(function() {
    function toggleMenu() {
        let menu = $('.mobile-menu');
        let display = menu.css('display');
        menu.css('display', display === 'none' ? 'block' : 'none');
        $('.page-wrapper').css('overflow', display === 'none' ? 'hidden' : 'visible');
        $('.page-wrapper').css('position', display === 'none' ? 'fixed' : 'relative');
    }

    $('.menu-button').click(function () {
        toggleMenu();
    });
    $('.top-close span').click(function () {
        toggleMenu();
    });

    $('.menu-item a').click(function () {
        $('.primary-menu').css('display', 'none');
    });

    let filterItem = $('.filter-button-group btn-filter');
    if (filterItem) {
        filterItem.click(function() {
            $('.filter-button-group btn-filter').removeClass('active');
            $(this).addClass('active');
        });
    }

    let faqPanel = $('.panel.left-panel-catch .panel-heading');

    if (faqPanel) {
        faqPanel.click(function() {
            let tab = $(this).data('tab');

            $('.panel.left-panel-catch .panel-heading').removeClass('active-side-accordion');
            $(this).addClass('active-side-accordion');

            $('.faq-description .tab-content').addClass('hidden');
            $('.' + tab).removeClass('hidden');
        });
    }

    let $subscribeForm = $('#subscribe-form');

    if ($subscribeForm) {
        let $successContainer = $('.subscribe-success');
        let $errorContainer = $('.subscribe-error');

        $subscribeForm.submit(function(event) {
            event.preventDefault();

            $successContainer.addClass('hidden');
            $errorContainer.addClass('hidden');

            let data = $subscribeForm.serializeArray();

            $.ajax( {
                type: "POST",
                url: $subscribeForm.attr( 'action' ),
                data: data,
                success: function(response) {
                    $successContainer.removeClass('hidden');
                },
                error: function(error) {
                    $errorContainer.html(error.responseJSON.message);
                    $errorContainer.removeClass('hidden');
                }
            } );
        });
    }

    // let $gdprCheckboxAction = $('.gdpr-checkbox-action');
    // if ($gdprCheckboxAction) {
    //     $gdprCheckboxAction.click(function(e){
    //         e.stopImmediatePropagation();
    //
    //         let $gdprCheckbox = $(this).closest('.gdpr-form').find('.gdpr-checkbox');
    //         if ($(this)[0] !== $gdprCheckbox[0]) {
    //             $gdprCheckbox.prop('checked', !$gdprCheckbox.is(':checked'));
    //         }
    //
    //         let $gdprSubmit = $(this).closest('.gdpr-form').find('.gdpr-submit');
    //         if ($gdprCheckbox.is(':checked')) {
    //             $gdprSubmit.removeAttr('disabled');
    //         } else {
    //             $gdprSubmit.prop('disabled', 'disabled');
    //         }
    //     });
    // }

    let $gdprSubmit = $('.gdpr-form .gdpr-submit');
    if ($gdprSubmit) {
        $gdprSubmit.click(function(e){
            let $gdprCheckbox = $(this).closest('.gdpr-form').find('.gdpr-checkbox');
            if ($(this)[0] !== $gdprCheckbox[0]) {
                $gdprCheckbox.prop('checked', !$gdprCheckbox.is(':checked'));
            }
        });
    }

    let $videoButton = $('.play-video-attraction');
    if ($videoButton) {
        $videoButton.click(function(e) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            let url = $(this).data('url');
            let match = url.match(regExp);
            let videoId = null;

            if (match && match[2].length === 11) {
                videoId = match[2];
            }

            $('#videoPopup iframe').prop('src', '//www.youtube.com/embed/' + videoId + '?autoplay=1');
            $('#videoPopup').modal();
            $("#videoPopup").on('hidden.bs.modal', function (e) {
                $("#videoPopup iframe").prop("src", '');
            });
        });
    }

    $('.cookie-message').cookieBar({ closeButton : '.cookie-close-button'});
});